<template>
  <div class="h-full flex items-center justify-center">
   <div class="flex flex-col items-center">
     <!-- <v-progress-circular
      class="text-center"
      indeterminate
      size="32"
    ></v-progress-circular> -->
    <p class="text-center text-h6">
      Click on the chat button to start a conversation with us.
    </p>
   </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      isChatOpen: false
    }
  },
  mounted() {
    const freshworks = document.createElement('script')
    freshworks.setAttribute('type', 'text/javascript')
    freshworks.setAttribute('src', '//uae.fw-cdn.com/40167963/102822.js')
    freshworks.setAttribute('chat', 'true')
    document.head.appendChild(freshworks)

    // const interval = setInterval(() => {
    //   if (this.isChatOpen) {
    //     clearInterval(interval)
    //   }

    //   const iframe = document.querySelector('iframe')
    //   console.log(iframe)
    //   if (iframe === null) {
    //     return
    //   }

    //   const chatButton = iframe.document.querySelector('#ember3')
    //   console.log(chatButton)
    //   if (chatButton === null) {
    //     return
    //   }

    //   chatButton.click()
    //   this.isChatOpen = true
    // }, 2000)
  }
}
</script>

<style></style>
